<template>
  <div class="chat">
    <!-- Loading -->
    <div v-if="isLoading" class="text-center loading-wrapper">
      <a-spin />
    </div>
    <!-- / Loading -->

    <!-- No chat -->
    <div v-if="!isLoading && !chat" class="no-chat-wrapper">
      <a-alert type="error" message="Error loading chat, please try again." />
    </div>
    <!-- / No chat -->

    <!-- Chat loaded -->
    <div v-if="!isLoading && chat" class="chat-inner">
      <a-layout id="chat-show-inner-layout">
        <a-layout :style="{ background: '#f7f8fa' }">
          <a-layout-content
            @mouseover="collapseRightSidebar"
            :style="{ padding: '20px 30px' }"
          >
            <div class="chat-wrapper">
              <div class="top">
                <div class="page-header">
                  <a-avatar
                    size="large"
                    icon="loading"
                    v-if="isLoadingMembership"
                  />
                  <chat-thumbnail
                    v-if="!isLoadingMembership"
                    size="large"
                    :multipleMembers="membership && membership.length > 2"
                    :first-presence-id="
                      membership.length ? membership[0].id : null
                    "
                    :first-presence-owner-id="
                      membership && membership.length
                        ? membership[0].ownerId
                        : null
                    "
                    :has-custom-avatar="chat.hasCustomAvatar"
                    :my-presence-id="myPresenceId"
                    :tenant-id="tenantId"
                    :chat-id="chat.id"
                    :chat-owner-id="chat.ownerId"
                    :can-edit="true"
                  ></chat-thumbnail>
                  <h1 class="page-title" v-if="!isEditingName">
                    {{ displayName }}
                  </h1>
                  <div class="header-inline-actions" v-if="!isEditingName">
                    <a-button
                      v-if="canEditChat"
                      @click.prevent="startEditingName"
                      size="small"
                      class="header-inline-action"
                      icon="edit"
                    ></a-button>
                    <a-tooltip v-else placement="top">
                      <template slot="title">
                        <span>This group is managed by your organisation</span>
                      </template>
                      <a-button
                        size="small"
                        icon="edit"
                        disabled
                        class="header-inline-action"
                      ></a-button>
                    </a-tooltip>
                  </div>
                  <a-input
                    @keyup.enter="saveNewName"
                    size="large"
                    class="name-input"
                    v-model="newName"
                    v-if="isEditingName"
                    :disabled="isSavingName"
                  ></a-input>
                  <div class="header-inline-actions" v-if="isEditingName">
                    <a-button
                      @click.prevent="saveNewName"
                      :disabled="isSavingName"
                      type="primary"
                      icon="check"
                      size="large"
                    ></a-button>
                    <a-button
                      :disabled="isSavingName"
                      @click.prevent="cancelEditingName"
                      type="danger"
                      icon="cross"
                      size="large"
                    ></a-button>
                  </div>

                  <pin-button :guid="chat.id" size="small"></pin-button>

                  <!-- <leave-group-button
                    v-if="!isManagedGroup"
                  ></leave-group-button> -->
                </div>
              </div>
              <div class="middle">
                <timeline
                  :key="`timeline-${chat.id}`"
                  :membership="membership"
                  :owner-type="2"
                  :owner-id="chat.id"
                  :timeline="timelineGroupedByDate"
                  :my-presence-id="myPresenceId"
                  :tenant-id="tenantId"
                ></timeline>
              </div>
              <div class="bottom">
                <typing-indicators
                  :my-presence-id="myPresenceId"
                ></typing-indicators>
                <div class="bottom-toolbar-wrapper">
                  <div class="left">
                    <send-message-actions
                      type="chat"
                      @add-staff-members="addStaffMembers"
                      :cannot-add-staff-members="isManagedGroup"
                      :membership="membership"
                      :tenant-id="tenantId"
                      :org-id="chat.ownerId"
                      :my-presence-id="myPresenceId"
                    ></send-message-actions>
                  </div>
                  <div class="right">
                    <send-message :my-presence-id="myPresenceId"></send-message>
                  </div>
                </div>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
        <a-layout-sider
          width="300"
          id="chat-show-right-sidebar"
          style="background: #fff"
          v-model="rightbarCollapsed"
          collapsible
          reverse-arrow
          theme="light"
        >
          <collapsed-sidebar
            v-if="rightbarCollapsed"
            @expand="expandRightSidebar"
          ></collapsed-sidebar>

          <expanded-sidebar
            v-if="!rightbarCollapsed"
            @collapse="collapseRightSidebar"
          >
          </expanded-sidebar>
        </a-layout-sider>
      </a-layout>
    </div>
    <!-- / Chat loaded -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CollapsedSidebar from "./Show/CollapsedSidebar.vue";
import ExpandedSidebar from "./Show/ExpandedSidebar.vue";
import SendMessage from "../../components/Timelines/SendMessage.vue";
import Timeline from "../../components/Timelines/Timeline.vue";
import chats from "../../api/chats";
// import LeaveGroupButton from "./Show/LeaveGroupButton.vue";
import SendMessageActions from "../../components/SendMessageActions.vue";
import ChatThumbnail from "../../components/Chats/ChatThumbnail.vue";
// import eventBus from "../../event-bus";
import UpdatesLastReadPointer from "../../mixins/UpdatesLastReadPointer";
import TypingIndicators from "../../components/TypingIndicators.vue";
import PinButton from "../../components/PinButton.vue";
const _ = require("lodash");
export default {
  name: "Show",
  components: {
    CollapsedSidebar,
    SendMessage,
    ExpandedSidebar,
    Timeline,
    // LeaveGroupButton,
    SendMessageActions,
    ChatThumbnail,
    TypingIndicators,
    PinButton
  },
  mixins: [UpdatesLastReadPointer],
  data() {
    return {
      rightbarCollapsed: true,

      isEditingName: false,
      newName: "",
      isSavingName: false,

      notifyArrivalHeartbeatEnabled: false,
    };
  },
  watch: {
    chat: {
      handler(newVal) {
        if (newVal) {
          // this.loadGroupMembers(newVal.id);
          // this.loadMemberships(newVal.id);
          this.setMetadataIds(newVal.metadataIds ? newVal.metadataIds : []);
          this.loadTimeline({
            model: "timeline",
            tenantId: this.tenantId,
            guid: newVal.id,
            ownerType: 2,
            ownerId: newVal.id,
            organisationId: newVal.ownerId,
            behaviour: 0,
          });
          this.loadQuickMessages();

          if (!this.notifyArrivalHeartbeatEnabled) {
            this.notifyArrivalHeartbeatEnabled = true;
            this.notifyArrival();
            this.updateMyLastReadPointer();
          }
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters("chatViewer", {
      chat: "chat",
      isLoading: "isLoading",
      myPresenceId: "myPresenceId",
      membership: "membership",
      canEditChat: "canEditChat",
      isManagedGroup: "isManagedGroup",
      isLoadingMembership: "isLoadingMembership",
    }),

    ...mapGetters("timeline", {
      timelineGroupedByDate: "timelineGroupedByDate",
    }),

    ...mapGetters("identifier", {
      guid: "guid",
    }),

    tenantId() {
      return this.$route.params.tenantId;
    },

    displayName() {
      if (this.chat.displayName && this.chat.displayName.length) {
        return this.chat.displayName;
      }
      if (this.isLoading) {
        return "Loading";
      }
      if (this.membership.length) {
        let membersNotMe = _.filter(this.membership, (member) => {
          return member.id !== this.myPresenceId;
        });
        let displayNames = _.map(membersNotMe, "displayName");
        return displayNames.length ? displayNames.join(", ") : "Unknown";
      }
      return "Unknown";
    },
  },
  methods: {
    ...mapActions("chats", {
      updateChatGroupMember: "updateChatGroupMember",
      // loadGroupMembers: "loadGroupMembers",
      // loadMemberships: "loadMemberships"
    }),

    ...mapActions("chatViewer", {
      loadChat: "loadChat",
    }),

    ...mapActions("timeline", {
      loadTimeline: "loadTimeline",
      loadQuickMessages: "loadQuickMessages",
    }),

    ...mapActions("metadata", {
      setMetadataIds: "setMetadataIds",
    }),

    expandRightSidebar() {
      this.rightbarCollapsed = false;
    },

    collapseRightSidebar() {
      this.rightbarCollapsed = true;
    },

    startEditingName() {
      this.isEditingName = true;
      this.newName = this.chat.displayName;
    },

    cancelEditingName() {
      this.isEditingName = false;
    },

    addStaffMembers(staffMemberIds) {
      let vm = this;
      vm.isAddingStaffMembers = true;
      chats
        .addMembersToChat(
          this.tenantId,
          this.chat.id,
          staffMemberIds,
          this.myPresenceId
        )
        .then(() => {
          vm.isAddingStaffMembers = false;
          vm.$message.success("Staff members added successfully");
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingStaffMembers = false;
          vm.$message.error("Error adding staff members to incident");
        });
    },

    saveNewName() {
      let vm = this;

      // if (!vm.newName.length) {
        // return false;
      // }

      if (!isNaN(vm.newName)) {
        vm.$message.error('New name cannot be only numbers');
        return false;
      }

      vm.isSavingName = true;
      chats
        .renameChat(
          this.tenantId,
          this.chat.id,
          this.myPresenceId,
          String(this.newName)
        )
        .then(() => {
          vm.isSavingName = false;
          vm.isEditingName = false;
          // vm.chatNameUpdated(vm.newName);
          vm.$message.success("Name updated successfully");
        })
        .catch((e) => {
          console.log(e);
          vm.isSavingName = false;
          vm.$message.error("Error updating name");
        });
    },

    async notifyArrival() {
      // alert('Notify arrival');
      if (!this.notifyArrivalHeartbeatEnabled) {
        return false;
      }
      if (this.$route.name !== "View Chat") {
        this.notifyArrivalHeartbeatEnabled = false;
        return false;
      }
      try {
        let params = {
          InstallationId: this.guid,
          CurrentTenantId: this.tenantId,
          CurrentGroupId: this.chat.id,
          CurrentPresenceId: this.myPresenceId,
        };
        console.log("Sending NotifyClientAvailable heartbeat:");
        console.log(params);

        await this.$signalR.invoke("NotifyClientAvailable", params);
        console.log("Heartbeat sent successfully");
        // this.$message.info("Sent NotifyClientAvailable heartbeat");
      } catch (err) {
        console.log("Error notifying arrived");
        console.log(err);
      }

      // Let's trigger another heartbeat in 60 seconds
      let vm = this;
      window.setTimeout(function () {
        vm.notifyArrival();
      }, 60000);
    },

    async notifyDepart() {
      try {
        let params = {
          InstallationId: this.guid,
          CurrentTenantId: null,
          CurrentGroupId: null,
          CurrentPresenceId: null,
        };
        console.log("Sending NotifyClientAvailable departure:");
        console.log(params);

        await this.$signalR.invoke("NotifyClientAvailable", params);
        console.log("Depart message sent successfully");
        // this.$message.info("Sent NotifyClientAvailable heartbeat");
      } catch (err) {
        console.log("Error notifying departed");
        console.log(err);
      }
    },

    listenToSignalRControlMessage() {
      let vm = this;
      this.$signalR.on("ControlMessage", (controlMessage) => {
        console.log("Control Message:");
        console.log(controlMessage);

        let messageBody = JSON.parse(controlMessage["messageBody"]);
        // let groupId = messageBody["groupId"];
        let presenceId = messageBody["presenceId"];

        // if (groupId !== this.chat.id) {
        // return false
        // }

        // Set presence active
        if (controlMessage.messageType === 3) {
          this.setPresenceActive(presenceId);
        }

        // Set presence inactive
        if (controlMessage.messageType === 4) {
          this.setPresenceInactive(presenceId);
        }

        // Update group member
        if (controlMessage.messageType === 13) {
          let groupMemberDetails = messageBody.groupMemberDetails;
          if (groupMemberDetails.groupId === this.incident.id) {
            this.updateChatGroupMember(groupMemberDetails);
          }
        }

        // Closed
        if (controlMessage.messageType === 6) {
          if (controlMessage.targetGroupId === this.chat.id) {
            if (
              !window.idsOfClosedChats ||
              (window.idsOfClosedChats &&
                !window.idsOfClosedChats.includes(this.chat.id))
            ) {
              vm.$message.info("Chat has been closed");
              vm.$router.push("/chats");
              if (!window.idsOfClosedChats) {
                window.idsOfClosedChats = [this.chat.id];
              } else {
                window.idsOfClosedChats.push(this.chat.id);
              }
            }
          }
        }
      });
    },

    listenToSignalRParticipantAddedMessage() {
      this.$signalR.on("AddParticiant", (participantAddedMessage) => {
        console.log("Participant Added");
        console.log(participantAddedMessage);

        let tenantId = participantAddedMessage.tenantId;
        let groupMemberDetails = participantAddedMessage.groupMemberDetails;

        let groupId = groupMemberDetails.groupId;
        if (tenantId === this.tenantId && groupId === this.chat.id) {
          this.handleParticipantAdded(groupMemberDetails);
        }
      });
    },

    updateMyLastReadPointer() {
      let vm = this;
      this.updateChatGroupMember({
        presenceId: vm.myPresenceId,
        groupId: vm.chat.id,
        groupMemberDetails: {
          lastReadPointer: window.moment(),
        },
      });
      this.updateServerLastReadPointer(
        vm.tenantId,
        vm.myPresenceId,
        vm.chat.id
      );
    },
  },
  created() {
    // this.listenToSignalRControlMessage();
    // this.listenToSignalRParticipantAddedMessage();
    // eventBus.$on("chatLoaded", this.notifyArrival);

    let idFromUrl = this.$route.params.id;
    let isThisChatLoaded = this.chat && this.chat.id === idFromUrl;
    if (!isThisChatLoaded) {
      this.loadChat({
        tenantId: this.tenantId,
        id: idFromUrl,
      });
    }
  },
  beforeDestroy() {
    this.notifyArrivalHeartbeatEnabled = false;
    this.notifyDepart();
  },
};
</script>

<style scoped lang="scss">
.loading-wrapper {
  margin-top: 50px;
}

.leave-group-button-wrapper {
  flex-grow: 1;
  text-align: right;
}

.no-chat-wrapper {
  margin-top: 50px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.chat,
.chat-inner,
#chat-show-inner-layout {
  height: 100%;
  max-height: 100%;
}

#chat-show-right-sidebar {
  box-shadow: 0 0 8px rgb(0 0 0 / 4%);
  z-index: 2;

  .ant-layout-sider-children {
    border-left: 1px solid #d9d9d9;
  }
}

.chat-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    flex-shrink: 1;

    .page-header {
      margin-bottom: 0;

      .page-title {
        margin-left: 15px;
        flex: none;
        margin-bottom: 0;
      }

      .name-input {
        max-width: 300px;
      }

      .header-inline-actions {
        margin-left: 10px;

        .ant-btn {
          margin-right: 5px;
        }
      }
    }
  }

  .middle {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .bottom {
    padding-top: 15px;
    flex-shrink: 1;

    .bottom-toolbar-wrapper {
      display: flex;

      .left {
        flex-shrink: 1;
        padding-right: 15px;
        display: flex;
        align-items: center;
      }

      .right {
        flex-grow: 1;
      }
    }
  }
}
</style>
<template>
  <div class="expanded-sidebar">
    <div class="top presences">
      <!-- Active -->
      <div
        class="presence"
        v-for="(member, i) in activeMembersWithMeOnTop"
        :key="getActiveKey(i)"
      >
        <div class="left">
          <presence-thumbnail
            :owner-id="member.ownerId"
            :presence-id="member.id"
          >
          </presence-thumbnail>
        </div>

        <div class="right">
          {{ formatMemberName(member.displayName, member.id) }}
        </div>
      </div>
      <!-- / Active -->

      <!-- Inactive -->
      <div
        class="presence"
        v-for="(member, i) in inactiveMembersWithMeOnTop"
        :key="getInactiveKey(i)"
      >
        <div class="left">
          <presence-thumbnail
            style="opacity: 0.3"
            :owner-id="member.ownerId"
            :presence-id="member.id"
          >
          </presence-thumbnail>
        </div>

        <div class="right">
          {{ formatMemberName(member.displayName, member.id) }}
        </div>
      </div>
      <!-- / Inactive -->
    </div>

    <div class="bottom" v-if="!isManagedGroup">
      <archive-chat-button></archive-chat-button>

      <leave-group-button></leave-group-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
import ArchiveChatButton from './ArchiveChatButton.vue';
import LeaveGroupButton from "./LeaveGroupButton.vue";
const _ = require("lodash");
export default {
  components: { PresenceThumbnail, LeaveGroupButton, ArchiveChatButton },
  computed: {
    ...mapGetters("chatViewer", {
      activeMembers: "activeMembers",
      inactiveMembers: "inactiveMembers",
      isManagedGroup: "isManagedGroup",
    }),

    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    myPresenceIds() {
      return _.map(this.presences, "id");
    },

    activeMembersWithMeOnTop() {
      let vm = this;
      if (vm.myPresenceIds) {
        let membersThatAreMe = _.filter(vm.activeMembers, (member) => {
          return vm.myPresenceIds.includes(member.id);
        });
        let membersThatAreNotMe = _.filter(vm.activeMembers, (member) => {
          return !vm.myPresenceIds.includes(member.id);
        });
        return _.flattenDeep([membersThatAreMe, membersThatAreNotMe]);
      }
      return vm.activeMembers;
    },

    inactiveMembersWithMeOnTop() {
      let vm = this;
      if (vm.myPresenceIds) {
        let membersThatAreMe = _.filter(vm.inactiveMembers, (member) => {
          return vm.myPresenceIds.includes(member.id);
        });
        let membersThatAreNotMe = _.filter(vm.inactiveMembers, (member) => {
          return !vm.myPresenceIds.includes(member.id);
        });
        return _.flattenDeep([membersThatAreMe, membersThatAreNotMe]);
      }
      return vm.inactiveMembers;
    },
  },
  methods: {
    getActiveKey(i) {
      return "active-" + i;
    },

    getInactiveKey(i) {
      return "inactive-" + i;
    },

    formatMemberName(memberName, presenceId) {
      if (_.map(this.presences, "id").includes(presenceId)) {
        return "You (" + memberName + ")";
      }
      return memberName;
    },
  },
};
</script>

<style scoped lang="scss">
.expanded-sidebar {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  // overflow-y: scroll;

  .top {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .bottom {
    flex-shrink: 1;
  }

  .archive-chat-button-wrapper {
    margin-bottom: 10px;
  }
}

.presence {
  margin-bottom: 15px;
  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .right {
    padding-left: 15px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="leave-group-button-wrapper">
    <a-button
      @click.prevent="openModal"
      class="leave-group-button btn-rounded"
      size="large"
      block
      type="danger"
    >
      Leave Group
    </a-button>

    <a-modal
      v-model="modalVisible"
      @ok="leaveGroup"
      ok-text="Leave Group"
      title="Are you sure?"
    >
      <loading-screen :is-loading="isSaving"></loading-screen>
      <p>Are you sure you want to leave this group?</p>
    </a-modal>
  </div>
</template>

<script>
import LoadingScreen from "../../../components/LoadingScreen.vue";
import chats from "../../../api/chats";
import { mapGetters } from "vuex";
export default {
  components: { LoadingScreen },
  data() {
    return {
      isSaving: false,
      modalVisible: false,
    };
  },
  computed: {
    ...mapGetters("chatViewer", {
      tenantId: "tenantId",
      chat: "chat",
      myPresenceId: "myPresenceId",
    }),
  },
  methods: {
    openModal() {
      let vm = this;
      vm.modalVisible = true;
    },

    leaveGroup() {
      let vm = this;
      vm.isSaving = true;
      chats
        .deleteMemberFromChat(
          this.tenantId,
          this.chat.id,
          this.myPresenceId,
          this.myPresenceId
        )
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Left chat successfully");
          vm.$router.push("/chats");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error leaving chat");
        });
    },
  },
};
</script>

<style>
</style>
<template>
    <div class="archive-chat-button-wrapper">
        <a-button @click.prevent="openModal" class="archive-chat-button btn-rounded" size="large" block type="danger">
            Archive Chat
        </a-button>

        <a-modal v-model="modalVisible" @ok="archiveChat" ok-text="Archive Chat" title="Are you sure?">
            <loading-screen :is-loading="isSaving"></loading-screen>
            <p>Are you sure you want to archive this chat?</p>
        </a-modal>
    </div>
</template>

<script>
import LoadingScreen from '../../../components/LoadingScreen.vue';
import chats from '../../../api/chats';
import { mapGetters } from 'vuex';

export default {
    components: { LoadingScreen },

    data() {
        return {
            modalVisible: false,
            isSaving: false
        }
    },

    computed: {
        ...mapGetters('chatViewer', {
            tenantId: 'tenantId',
            chat: 'chat',
            myPresenceId: 'myPresenceId'
        })
    },

    methods: {
        openModal() {
            this.modalVisible = true;
        },

        archiveChat() {
            let vm = this;
            vm.isSaving = true;
            chats.archiveChat(
                this.tenantId,
                this.chat.id,
                this.myPresenceId
            ).then(() => {
                vm.isSaving = false;
                vm.$message.success('Chat archived successfully');
                vm.$router.push('/chats');
            }).catch(e => {
                console.log(e);
                vm.isSaving = false;
                vm.$message.error('Error archiving chat');
            });
        }
    }
}
</script>

<style scoped lang="scss">
.archive-chat-button-wrapper {}
</style>
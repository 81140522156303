<template>
  <div
    class="collapsed-sidebar"
    @mouseover="$emit('expand')"
    @click.prevent="$emit('expand')"
  >
    <div class="presence-thumbnails">
      <!-- Active Members -->
      <div
        @click="$emit('expand')"
        class="presence-thumbnail"
        v-for="member in activeMembersWithMeOnTop"
        :key="getActiveKey(member.id)"
      >
        <a-tooltip placement="left">
          <template slot="title">
            <span>{{ formatMemberName(member.displayName, member.id) }}</span>
          </template>
          <presence-thumbnail
            size="large"
            :owner-id="member.ownerId"
            :presence-id="member.id"
          >
          </presence-thumbnail>
        </a-tooltip>
      </div>
      <!-- / Active Members -->

      <!-- Inactive Members -->
      <div
        @click="$emit('expand')"
        class="presence-thumbnail"
        v-for="member in inactiveMembersWithMeOnTop"
        :key="getInactiveKey(member.id)"
      >
        <a-tooltip placement="left">
          <template slot="title">
            <span>{{ member.displayName }}</span>
          </template>
          <presence-thumbnail
            size="large"
            style="opacity: 0.3"
            :owner-id="member.ownerId"
            :presence-id="member.id"
          >
          </presence-thumbnail>
        </a-tooltip>
      </div>
      <!-- / Inactive Members -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
export default {
  components: { PresenceThumbnail },
  computed: {
    ...mapGetters("chatViewer", {
      activeMembers: "activeMembers",
      inactiveMembers: "inactiveMembers",
    }),

    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    myPresenceIds() {
      return _.map(this.presences, "id");
    },

    activeMembersWithMeOnTop() {
      let vm = this;
      if (vm.myPresenceIds) {
        let membersThatAreMe = _.filter(vm.activeMembers, (member) => {
          return vm.myPresenceIds.includes(member.id);
        });
        let membersThatAreNotMe = _.filter(vm.activeMembers, (member) => {
          return !vm.myPresenceIds.includes(member.id);
        });
        return _.flattenDeep([membersThatAreMe, membersThatAreNotMe]);
      }
      return vm.activeMembers;
    },

    inactiveMembersWithMeOnTop() {
      let vm = this;
      if (vm.myPresenceIds) {
        let membersThatAreMe = _.filter(vm.inactiveMembers, (member) => {
          return vm.myPresenceIds.includes(member.id);
        });
        let membersThatAreNotMe = _.filter(vm.inactiveMembers, (member) => {
          return !vm.myPresenceIds.includes(member.id);
        });
        return _.flattenDeep([membersThatAreMe, membersThatAreNotMe]);
      }
      return vm.inactiveMembers;
    },
  },
  methods: {
    getActiveKey(i) {
      return "active-" + i;
    },

    getInactiveKey(i) {
      return "inactive-" + i;
    },

    formatMemberName(memberName, presenceId) {
      if (_.map(this.presences, "id").includes(presenceId)) {
        return "You (" + memberName + ")";
      }
      return memberName;
    },
  },
};
</script>

<style scoped lang="scss">
.collapsed-sidebar {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  text-align: center;
  overflow-y: scroll;

  // No scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.presence-thumbnail {
  margin-bottom: 15px;
  cursor: pointer;
}
</style>